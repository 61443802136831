<ng-template #pocision let-offcanvas>
    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="col-12 ">
                <div class="row">
                    <div class="col-12 col-md-4 offset-md-4">
                        <div class="text-capitalize h4 text-secondary mt-2 ">
                            Resultado

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4 ">
                <ng-container *ngIf="searchPerson?.person">

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">


                        <div class="row rmt-2">
                            <div class="col-12">

                                <div class="float-end">

                                    <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                    <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                </div>
                                <div class="small text-secondary">Credito total</div>
                                <h5 class="fw-bolder text-dark"> {{searchPerson?.total_currency[currencyResponse]?.total_amount | currency:'USD':'': '1.0-2'}} <span class="small">{{currencyResponse}}</span></h5>
                            </div>
                            <div class="col-6">
                                <div class="small text-secondary">Cupo disponible</div>
                                <div class=" text-dark"> {{searchPerson?.total_quota | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-12 ">
                                <div class="small text-secondary">Plan</div>
                                <div class="text-dark">50% Inicial min.</div>
                            </div>
                            <div class="col-12">
                                <div class="small text-secondary">Cuotas</div>
                                <div class=" text-dark">3</div>
                            </div>
                            <div class="col-6 ">
                                <div class="small text-secondary">Inicial</div>
                                <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.initiala_amount | currency:(currencyResponse== 'BS'?'VED':currencyResponse):'': '1.0-2'}} <span class="small">{{currencyResponse}}</span></div>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Monto a financiar</div>
                                <div class=" text-dark">{{searchPerson?.outstanding_balance | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>
                        </div>
                    </div>

                    <h4 class="mt-4 mb-2 text-primary text-center" *ngIf="searchPerson?.installments">Cuotas {{searchPerson?.installments?.length}}</h4>
                    <div *ngFor=" let item of searchPerson?.installments;let i = index" class="mt-4 my-3 p-3 bg-body2  rounded shadow-sm">

                        <div class="row rmt-2">
                            <div class="col-12 mt-2">

                                <h5 class="fw-bolder text-dark"> {{ i
                                    < 1 ? 'Inicial': 'cuota '+i}}</h5>
                            </div>
                            <div class="col-6">
                                <div class="small text-secondary">Total</div>
                                <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-6 ">
                                <div class="small text-secondary">Estatus</div>
                                <div class="text-dark" [title]="item?.payment_status?.description">{{item?.payment_status?.name}} </div>
                            </div>
                            <div class="col-12">
                                <div class="small text-secondary">Fecha del pago</div>
                                <!-- <div class=" text-dark"> {{item?.scheduledPaymentDate }}</div> -->
                                <div class=" text-dark"> {{item?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>


                            </div>
                            <div class="col-6 ">
                                <div class="small text-secondary">Pagada</div>
                                <div class=" text-dark"> {{0 | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-6 ">
                                <div class="small text-secondary">Restante</div>
                                <div class=" text-dark"> {{0 | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>
                        </div>
                    </div>


                    <!-- <pre>{{ searchPerson?.installments | json }}</pre> -->
                </ng-container>

            </div>
        </div>

    </div>
</ng-template>



<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12   p-0 ">
                <h5 class="mb-4 text-secondary"> <i class="  fas fa-users"></i> Calculadora

                </h5>
                <ng-container *ngIf="!rate">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

                <ng-container *ngIf="rate">
                    <div class="mt-3 my-3 p-3 bg-body rounded Consulta cupo shadow-sm">

                        <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                            <div class="row mt-3">

                                <div class="form-group  col-12 mt-3">
                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.currency.invalid && form.controls.currency.dirty}" class="form-select" id="currency" formControlName="currency" aria-label="Floating label select example">
                                            <option  value="BS">Bolivares</option>
                                            <option  value="USD">Dolares</option>

                                     </select>
                                        <label for="currency">Moneda</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                                        <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                            Campo requerido
                                        </div>
                                    </div>

                                </div>


                                <div class="form-group  col-12  mt-3">

                                    <div class="form-floating ">
                                        <input type="number" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}" class="form-control text-capitalize" id="total_amount" formControlName="total_amount">
                                        <label for="total_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                                      <span >Monto Total </span>
                                 </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                                        <div *ngIf="form.get('total_amount').errors['required'] && !form.get('total_amount').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('total_amount').hasError('min')  && !form.get('total_amount').pristine">
                                            Mínimo 20 Dolares
                                        </div>


                                    </div>
                                </div>
                                <div class="form-group  col-12  mt-3">
                                    <label for="chage_rate" class=" label font-weight-normal text-secondary small" style="word-wrap: break-word;">
                                    <span >Tasa de cambio </span>
                                 </label>
                                    <div class=" text-dark"> USD 1 = BS {{rate?.amount | currency:'USD':'': '1.2-2'}}</div>
                                </div>
                                <br>
                                <div class="form-group  col-12 mt-4 mb-2">
                                    <div class=" float-end">

                                        <button (click)="pocisionBottom(pocision)" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                        <span >Consultar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                                      </button>

                                    </div>

                                </div>


                                <hr *ngIf="errorMsg" class="mt-2">
                                <div *ngIf="errorMsg" class="text-danger">
                                    {{errorMsg}}
                                </div>



                            </div>
                        </form>


                    </div>
                </ng-container>



            </div>
        </div>
    </div>
</div>