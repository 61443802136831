<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row mt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center">
                        <i class="  fas fa-user"></i> Cuotas
                    </h5>

                </div>
            </div>

            <ng-container *ngIf="!loading">


                <div class="row">



                    <div class="col-12  col-md-8 offset-md-2 mt-4">

                        <div class="mb-4 mt-2 col-12 col-md-6 ">
                            <div class="form-floating ">
                                <select (click)="setTypeInstallMents()" [(ngModel)]="typeInstallMents" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                                  <option   value="">Todos</option>
                                  <option   value="p">Pagos pendientes</option>
                                  <option   value="c">Pagos Consolidados</option>
                                  <option   value="p-next">Proximo pago pendiente</option>
                                </select>
                                <label for="floatingSelect ">Estatus</label>
                            </div>

                        </div>

                        <div class="mt-2  mb-4 p-3 bg-body rounded shadow-sm" *ngFor="let item of installMents">
                            <div class="row mt-2  row-k">


                                <ng-container *ngFor="let item2 of item?.installMents; let i=index">
                                    <div class="col-12 mb-2">
                                        <div class=" text-dark fw-bold cursor-pounter">Cuota {{item2?.order}}/{{item?.invoice?.plan?.number_installment}} ({{item?.invoice?.identifier}})
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="small text-secondary">Monto</div>
                                        <div class=" text-dark"> {{item2?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                                    </div>

                                    <div class="col-6 text-end ">
                                        <div class="small text-secondary ">Fecha</div>
                                        <div class=" text-dark"> {{item2?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>
                                    </div>

                                    <div class="col-6">
                                        <div class="small text-secondary ">Cliente</div>

                                        <div class=" text-dark cursor-pounter">{{item?.invoice?.customer?.name}} {{item?.invoice?.customer?.lastName}}</div>
                                    </div>

                                    <div class="col-6 text-end ">
                                        <a href="" *ngIf="item2?.dueBalance != item2?.amount"> Ver pagos</a>
                                    </div>


                                    <div class="col-6 mt-3" *ngIf="item2?.payment_status?.code != 'PaymentComplete'">
                                        <span class="badge bg-primary">{{item2?.payment_status?.name}}</span>
                                    </div>

                                    <div class="col-6 mt-3" *ngIf="item2?.payment_status?.code == 'PaymentComplete'">
                                        <span class="badge bg-success">{{item2?.payment_status?.name}}</span>
                                    </div>



                                    <div *ngIf="item2?.payment_status?.code != 'PaymentComplete'" class="col-6 text-end mt-3">
                                        <button type="button" class="btn btn-outline-primary mt-2 btn-sm" [routerLink]="['/console/payment/'+item?.invoice?.code+'/'+item2?.code]"> Pagar {{item2?.dueBalance | currency:'USD':'': '1.0-2'}} USD faltante </button>
                                    </div>



                                    <div class="col-12">
                                        <hr *ngIf="(i+1) < item?.installMents?.length">
                                    </div>

                                </ng-container>


                                <!-- <pre>{{ item | json }}</pre> -->

                            </div>

                        </div>

                    </div>

                </div>

            </ng-container>

            <div *ngIf="!loading && installMents?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron </div>
                </div>
            </div>

            <div *ngIf="loading" class="text-center">
                <div>Cargando...</div>
            </div>
        </div>
    </div>

</div>