

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-installments-console',
  templateUrl: './installments-console.component.html',
  styleUrls: ['./installments-console.component.css'],
  providers: [DatePipe]

})
export class InstallmentsConsoleComponent implements OnInit{

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installMents: any = [];
   installmentNexPayment :any;
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    stadistic:any;
    typeInstallMents= 'p-next';
    
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService


  ) { }

  ngOnInit(): void {
    this.loading_person = true;

  
    this.loading = true;

    this.socketService.resetstadistic.subscribe((stadistic: any) => {
      this.getListInstallMents();
    });

  }


  getStadisticServer = async () => {
    try{
        return this._user.getStadistic();

      } catch (error) {
        console.error(error);
        
      }
  }
  getListInstallMents = async () => {
    try {


      const installMents = await this.getListInstallMentsService();
      this.installMents = installMents?.res;

    } catch (error) {
      console.error(error);

    } 

  
    this.loading = false;

  }


  getListInstallMentsService = async () => {
    try{

      let query = this.typeInstallMents != '' ? {i: this.typeInstallMents}: {};
        return this._user.getInstallMents(query);

      } catch (error) {
        console.error(error);
        
        
      }
  }




  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }



redirectTo(code:any) {
  this.router.navigate([`/console/payments/person/${code}`], {});
}


setTypeInstallMents(){
  this.getListInstallMents();


}

}
