import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-complete-biometria',
  templateUrl: './complete-biometria.component.html',
  styleUrls: ['./complete-biometria.component.css']
})
export class CompleteBiometriaComponent implements OnInit {

  person_code:any;
  parametro: any;
  timeStatus :any = false;

  constructor(   
    private _user:UserService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    

   }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {

      debugger
      this.person_code = params.get('c');
      
        this.setStatus()

        setTimeout(() => {
          this.timeStatus = true;
        }, 10000);
  
      

    });
  }


  setStatus = async () =>  {

    try {
      const params = {

        c: this.person_code
      
      }

      const resp =  await this._user.restet_view(params);

      setTimeout(() => {
        this.setStatus();
    }, 3000);



    } catch (error) {

      console.log(error);
      
      
    }


  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  cerrarPestana() {
    window.open('', '_self', '');
    window.close();
  }
  
}
