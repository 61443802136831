<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12  ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Datos del Perfil
                                <span *ngIf="view == 2" class="text-dark"> (Direcciones)</span>
                                <span *ngIf="view == 3" class="text-dark"> (Condición Laboral)</span>
                                <span *ngIf="view == 4" class="text-dark"> (Ingreso Promedio)</span>
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view = 1 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12">
                    <!-- 
                    <app-data-profile [person]="person" [code_person]="person?.person?.code" *ngIf="view == 1" (changeView)="setChangView($event)"></app-data-profile>
                    <app-address [code_person]="person?.person?.code" *ngIf="view == 2" (changeView)="setChangView($event)"></app-address>
                    <app-working-condition [code_person]="person?.person?.code" *ngIf="view == 3" (changeView)="setChangView($event)"></app-working-condition>
                    <app-income [code_person]="person?.person?.code" *ngIf="view == 4" (changeView)="setChangView($event)"></app-income> -->

                </div>
            </div>
        </div>
    </div>
</ng-template>


<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12">
            <ng-container *ngIf="!loading && person?.person" class="mt-2">

                <div class=" mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto">
                    <div *ngFor="let item of section_transactions" class="position-relative">

                        <label class="list-group-item py-3 " for="listGroupRadioGrid1">

                            
                            <ng-container *ngIf="section_transaction">

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'TERMS_AND_CONDITIONS'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm"   [routerLink]="['/verification/approve-conditions']">
                                           Completar
                                        </button>
                                    </span>

                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE' || item?.code == 'PRE_REGISTER' ">
                                    <span *ngIf="(item?.code == 'BURO' && !loading_buro && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code != 'BURO' && section_transaction[item?.code]?.condition ==  'COMPLETE')" class="fas fa-check-circle text-success float-end fz-25"></span>
                                    
                                     <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'BURO' && !loading_buro" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="getDataBuro(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_buro && item?.code == 'BURO'" class="small text-dark float-end">Cargando...</span>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('PHOTO_IDENTITY')">Completar</button>
                                    </span>

                                  
                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PROFILE_DATA'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="pocisionBottom(content)">Completar</button>
                                        
                                    </span>

                                    <div  *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.code == 'PROFILE_DATA'" class=" text-primary float-end me-2">
                                        
                                        <span (click)="pocisionBottom(content)"  class="text-secondary" > Editar</span>

                                    </div>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' &&  item?.code == 'SELFIE'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('SELFIE')">
                                            <span > Completar</span>

                                        </button>
                                        
                                    </span>

                                 

                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('SELFIE')" class="text-secondary" > Editar</span>

                                    </div>    

                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_selfie?.url)">Ver </span>

                                    </div>


                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'PHOTO_IDENTITY'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('PHOTO_IDENTITY')" class="text-secondary" > Editar</span>

                                    </div>    


                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_identity?.url)">Ver </span>

                                    </div>

                                </ng-container> 

                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition !=  'COMPLETE' && section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code != 'TERMS_AND_CONDITIONS'">

                                <span  class="small  float-end text-secondary" >Pendiente</span>
                                    </ng-container>

                            </ng-container>

                            <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE'" ></ng-container>

                                   <div class="mb-2"><strong class="fw-semibold">{{item.name}}</strong></div> 
                                    <span class="d-block small opacity-75">{{item.name}}</span>
                                    <span *ngIf="!loading_buro && item?.code == 'BURO' && buro" class="small text-dark mt-1">
                                     Fecha   <span class="fw-bold">{{buro.fechaConsulta | date: 'dd/MM/yyyy h:mm:ss a'}} </span> 
                                      <!-- <span *ngIf="buro_score"> / Score </span>
                                      <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>

                                    <span *ngIf="item?.code == 'TERMS_AND_CONDITIONS' && person?.person?.date_response_terms_and_conditions " class="small text-dark mt-1">
                                        Fecha de aprobación  <span class="fw-bold">  {{person?.person?.date_response_terms_and_conditions | date: 'dd/MM/yyyy'}}</span> 
                                         <!-- <span *ngIf="buro_score"> / Score </span>
                                         <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>
                                    
                         </label>
                    </div>

                </div>


            </ng-container>




            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>

</div>