<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12">
            <ng-container *ngIf="!loading && person?.person && terms_and_conditions && !acept" class="mt-2">

                <div class=" col-12  mt-4 ">

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                        <div class="fw-bold text-center h5">{{terms_and_conditions.name}}</div>
                        <div class="mt-3" style="text-align: justify;" [innerHTML]="terms_and_conditions.details">

                        </div>
                    </div>
                    <div class="text-center row mt-4" *ngIf="!hidenApro && !loading_term2">
                        <div class="col-12 col-md-4 offset-md-4">
                            <div class="d-grid gap-2">
                                <button class="btn btn-block btn-success " (click)="personApproveConditionsPost()">
                                <span >Aceptar</span>
                              </button>
                            </div>
                        </div>
                        <div class="text-secondary small mt-2">Al presionar aceptar, estara de acuerdo con los Terminos y condiciones</div>

                    </div>
                </div>
            </ng-container>

        </div>
    </div>

</div>