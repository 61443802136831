<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-8 offset-md-2  p-0 ">


                <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Opciones de Compras Financiadas
                </h5>

                <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">



                    <div class="row">

                        <div class="d-grid gap-2  col-12 col-md-6 mx-auto mt-2">

                            <button type="button" class="btn btn-primary  mb-3 " [routerLink]="['/console/stadistic']">Estadística de Compras F./Pagos</button>
                            <button type="button" class="btn btn-outline-primary  mb-3" [routerLink]="['/console/consult-quota']">Consultar Cupo</button>
                            <button type="button" class="btn btn-outline-primary mb-3" [routerLink]="['/console/credit-calculator']">Calculadora</button>
                            <!-- <button type="button" class="btn btn-outline-primary mb-3" [routerLink]="['/console/credit-save']">Crea Compra Financiada</button> -->
                            <button type="button" class="btn btn-outline-success  mb-3 " [routerLink]="['/console/online']">Usuarios en linea</button>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>