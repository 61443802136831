<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12">
            <ng-container class="mt-2">




                <div  class=" col-12  mt-4 ">

                    <h1>Política de Eliminación de Datos de Usuario</h1>
                    <br>
                    <p>En Mundo Total, respetamos y valoramos la privacidad de nuestros usuarios. Entendemos la importancia de proteger y gestionar adecuadamente sus datos personales. Por ello, hemos establecido una política clara y transparente para la eliminación de datos de usuario.</p>
                    <h2>Requisitos para Solicitar la Eliminación de Datos</h2>
                    <p>Para que un usuario pueda solicitar la eliminación de sus datos personales de nuestra plataforma, es necesario que cumpla con las siguientes condiciones:</p>
                    <ul>
                    <li>Pago Completo de Créditos: El usuario debe estar al día con todos los pagos de sus compras. Esto significa que no debe tener ningún crédito pendiente o cuotas sin pagar en nuestra plataforma.</li>
                    <li>Cierre de Créditos Activos: No debe haber créditos activos bajo el nombre del usuario. Todos los créditos deben haber sido completamente pagados y cerrados antes de solicitar la eliminación de los datos.</li>
                    </ul>
                    <h2>Procedimiento para Solicitar la Eliminación de Datos</h2>
                    <p>Si cumple con los requisitos mencionados, el usuario puede proceder a solicitar la eliminación de sus datos personales siguiendo estos pasos:</p>
                    <ol>
                    <li>Enviar Solicitud:</li>
                    <p>Instrucciones:</p>
                    <ol>
                    <li>Descargue y complete esta carta con sus datos personales.</li>
                    <li>Adjunte los documentos necesarios que prueben que no tiene créditos ni cuotas pendientes.</li>
                    <li>Envíe la <a target="_blank" href="../assets/public/document/Solicitud para Eliminar Mi Cuenta de Usuario CrediTotal.docx">carta </a> y los documentos adjuntos a <a href="mailto:soporte@creditotal.online">soporte@creditotal.online</a></li>
                    </ol>
                    <li>El usuario debe enviar una solicitud de eliminación de datos a nuestro equipo de soporte a través de [por ejemplo, correo electrónico, formulario en línea].</li>
                    </ol>
                    <h2>Verificación de Cumplimiento</h2>
                    <p>Nuestro equipo revisará la solicitud para asegurarse de que el usuario cumple con los requisitos de pago completo y cierre de créditos activos.</p>
                    <h2>Confirmación y Eliminación</h2>
                    <p>Una vez verificados los requisitos, se procederá a la eliminación de los datos del usuario de nuestros sistemas. El usuario recibirá una confirmación una vez que la eliminación haya sido completada.</p>
                    <h2>Consideraciones Adicionales</h2>
                    <p>La eliminación de datos es un proceso irreversible. Una vez eliminados, los datos no podrán ser recuperados.</p>
                    <p>En caso de que el usuario no cumpla con los requisitos de pago completo y cierre de créditos activos, se le notificará y se le proporcionarán detalles sobre los pasos necesarios para cumplir con estos requisitos antes de poder proceder con la eliminación de datos.</p>
                    <p>Si tiene alguna pregunta o necesita más información sobre nuestra política de eliminación de datos, no dude en ponerse en contacto con nuestro equipo de soporte.</p>

                    

                </div>
            </ng-container>



        </div>
    </div>

</div>