<div class="circle ms-auto me-auto mt-4 ">
    <div class="mask full" [style.transform]="getRotationStyle()"></div>
    <div class="mask half">
      <div class="fill" [style.transform]="getRotationStyle()"></div>
    </div>
    <div class="inside-circle">
      <p>{{ timeLeft }}</p>
    </div>
  </div>

  <div class="mt-3 text-secondary h4 text-center">
    Redirecionando..
  </div>
  