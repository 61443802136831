import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-option',
  templateUrl: './credit-option.component.html',
  styleUrls: ['./credit-option.component.css']
})
export class CreditOptionComponent implements OnInit {
   clientAprov:any;

  constructor() { }

  ngOnInit(): void {
  }

}
