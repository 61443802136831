
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-approve-conditions',
  templateUrl: './approve-conditions.component.html',
  styleUrls: ['./approve-conditions.component.css']
})

export class ApproveConditionsComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;
  
  isAuth:any;
  sessionUser:any;
  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   hidenApro = false;
   acept:any = false;
   msg:any;

   @Output() reset = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public _auth : AuthService

  ) { }

  ngOnInit(): void {

    
    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson()
      }
    });

/*
    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this._auth.sessionUser?.person_code) {
        this.getPerson();
      }else{
        this.rdrMarca();

      }

    });*/

  }

  getPerson = async () => {
    this.loading = true;

    debugger

    try {
      const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code  });
      this.person = resp.resp;
      
      if(!(this.person?.person) || !this.person?.person?.status_terms_and_conditions){

       // this.rdrMarca();
       this.loading = false;

      }else{

        this.acept = false;
        this.hidenApro = false;
        this.loading = false;

      }

    } catch (error) {
      this.rdrMarca();
      console.error(error);
      this.loading = false;

    }

  }
  

  personApproveConditionsPost = async () => {
      try {
    const data =  {
       code_person :this._auth.sessionUser?.person_code
      }

   //   this.reset.emit(true);

  
      let result = await this._user.personApproveConditionsPost(data);
  
      if (!(result?.error)) {
            this.msg = true;
            alertifyjs.success('Aceptación exitosa');
            this.acept = true;
           // this.rdrMarca();

        //   this.reset.emit(true);

        location.reload();

      }
  
  } catch (error) {
    console.error(error);
    
    
  }

}



rdrMarca(){
  window.location.href = environment.BASE_FORNT;

}


getContact(item: any) {

  if (!(item?.constacts?.length > 0))
    return "";

  return `${item?.constacts[0]?.contact}`;


}

getIdentity(item: any) {

  if (!(item?.identitys?.length > 0))
    return "";

  return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
