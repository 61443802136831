
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MbscScrollViewOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LaService } from 'src/app/modules/la/servises/la.service';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';

  @Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css'],
    providers: [DatePipe]

  })
  export class InvoicesComponent implements OnInit {

  scrollViewOptions: MbscScrollViewOptions = {
    layout: 'fixed',
    itemWidth: 300,
    snap: false
};

newGames = [{
    image: 'https://img.mobiscroll.com/demos/worms3.png',
    title: 'Worms 3',
    dev: 'Team 17 Digital Limited',
    rank: 4.2
}, {
    image: 'https://img.mobiscroll.com/demos/candycrush.png',
    title: 'Candy Crush Saga',
    dev: 'King',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/angrybirds.png',
    title: 'Angry Birds',
    dev: 'Rovino',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/nfs.png',
    title: 'Need for Speed™ No Limits',
    dev: 'ELECTRONIC ARTS',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/heartstone.png',
    title: 'Hearthstone',
    dev: 'Blizzard Entertainment Inc.',
    rank: 4.2
}, {
    image: 'https://img.mobiscroll.com/demos/fruitninja.png',
    title: 'Fruit Ninja',
    dev: 'Halfbrick Studios',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/subwaysurf.png',
    title: 'Subway Surfers',
    dev: 'Kiloo',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/templerun.png',
    title: 'Temple Run',
    dev: 'Imangi Studios',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/minecraft.png',
    title: 'Minecraft: Pocket Edition',
    dev: 'Mojang ',
    rank: 4.4
}];

mediaVideo = [{
    image: 'https://img.mobiscroll.com/demos/vlc.png',
    title: 'VLC for Android',
    dev: 'Videolabs ',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/realplayer.png',
    title: 'RealPlayer®',
    dev: 'RealNetworks, Inc.',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/motogal.png',
    title: 'Motorola Gallery',
    dev: 'Motorola Mobility LLC. ',
    rank: 3.9
}, {
    image: 'https://img.mobiscroll.com/demos/revesemov.png',
    title: 'Reverse Movie FX',
    dev: 'Bizo Mobile',
    rank: 4.6
}, {
    image: 'https://img.mobiscroll.com/demos/sure.png',
    title: 'SURE Universal Remote',
    dev: 'Tekoia Ltd.',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/ringdriod.png',
    title: 'Ringdroid',
    dev: 'Ringdroid Team ',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/funny.png',
    title: 'Funny Camera - Video Booth Fun',
    dev: 'Kiloo',
    rank: 4.1
}, {
    image: 'https://img.mobiscroll.com/demos/gif.png',
    title: 'GIF Keyboard',
    dev: 'IRiffsy, Inc.',
    rank: 4.1
}, {
    image: 'https://img.mobiscroll.com/demos/bs.png',
    title: 'BSPlayer',
    dev: 'BSPlayer media',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/vac.png',
    title: 'video audio cutter',
    dev: 'mytechnosound ',
    rank: 4.3
}];

newUpdated = [{
    image: 'https://img.mobiscroll.com/demos/netflix.png',
    title: 'Netflix',
    dev: 'Netflix, Inc. ',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/colorfy.png',
    title: 'Colorfy - Coloring Book Free',
    dev: 'Fun Games For Free',
    rank: 4.7
}, {
    image: 'https://img.mobiscroll.com/demos/wego.png',
    title: 'Wego Flights & Hotels',
    dev: 'Wego.com',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/ali.png',
    title: 'Alibaba.com B2B Trade App',
    dev: 'Alibaba.com Hong Kong Limited ',
    rank: 4.1
}, {
    image: 'https://img.mobiscroll.com/demos/5k.png',
    title: '5K Run: 5K Runner®',
    dev: 'Fitness22',
    rank: 4.4
}, {
    image: 'https://img.mobiscroll.com/demos/nuzzelnws.png',
    title: 'Nuzzel News',
    dev: 'Nuzzel, Inc.',
    rank: 4.3
}, {
    image: 'https://img.mobiscroll.com/demos/solarsysexpl.png',
    title: 'Solar System Explorer 3D',
    dev: 'Neil Burlock',
    rank: 4.5
}, {
    image: 'https://img.mobiscroll.com/demos/elevate.png',
    title: 'Elevate - Brain Training',
    dev: 'Elevate Labs',
    rank: 4.5
}, {
    image: 'https://img.mobiscroll.com/demos/deezer.png',
    title: 'Deezer Music',
    dev: 'Deezer Mobile',
    rank: 4.1
}];

quota:any = {total:50,disponible:0,utilizado:0};
mounto_pendiente_vencido:any = 0;
mounto_pendiente:any = 0;
shops:any = [];
shops_obj:any = {};

tp:any = 1;

buro: any;
loading_buro = false;

  loading: any = true;
  isAuth: any;
  sessionUser: any;
  person: any;
  imgPerson: any;
  usQuote: any = false;

  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  installments:any;
  installmentNexPayment:any;

  invoiceCredits:any = [];
  private socketServiceSubscribe:any  = Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,

  ) {
    
  }

  

  ngOnDestroy(): void {
    this.socketServiceSubscribe.unsubscribe();
  }

  ngOnInit(): void {

    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson();
      }
    });


    this.socketService.resetDataClientCode.subscribe((person_code: any) => {
      if(person_code && person_code == this._auth.sessionUser?.person_code){

        this.getSection_transaction_by_person();

      }
    });
    
  

   this.socketServiceSubscribe =  this.socketService.msgDataClient.subscribe((payload: any) => {

      if(payload?.code_person == this._auth?.sessionUser?.person_code  && payload?.type ==  'CREATE_INVOICE'){
       this.getPerson(false);
       }
       
     });
     this.getshops();
  }




getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


  getPerson = async (loadinge = true) => {

    try {
    
    const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
    this.person = resp.resp;

    this.quota =  {
        total:50,
        utilizado:this.toFixedApp(this.quota.total - this.person?.person?.quota ),
        disponible:this.toFixedApp(this.person?.person?.quota)
    };


    if(localStorage.getItem('active_cupo') && this.person?.person?.quota_active){

      this.toogleStatus(true); 
      localStorage.removeItem('active_cupo');

    }

    

    if (this.person?.person) {


        const resp_invoiceCredits = await this.listClientInvoiceCredits();

        
        let temp =  resp_invoiceCredits.res;

        let invoiceCredits:any = [];
        let installMent_promes:any =[]

        temp?.forEach((element:any) => {


            invoiceCredits = [...invoiceCredits,element];

            const data =  {
              code_invoice:element?.code 
             
             }
    
             installMent_promes  =  [...installMent_promes,this._user.getPersonInstallmentsInvoiceCredits(data)] ;
  
          

        });

        let installMents_base = await Promise.all(installMent_promes);


        this.getInvoicesAndInstallments(invoiceCredits,installMents_base);

       //this.invoiceCredits =  invoiceCredits;

        

    }
  
    this.imgPerson = this._auth.sessionUser?.file_selfie?.url;
      this.usQuote =  this.person?.person?.quota_client_active ? true:false;

    } catch (error) {
      console.error(error);

    }
  }

  

  getInvoicesAndInstallments = async (invoiceCredits_base:any,installMents_base:any) => {
    try {
        
      this.invoiceCredits  = invoiceCredits_base?.map((invoice:any, index:any) => {
        
        let installments:any = installMents_base[index]?.res;

        let installmentNexPayment:any ;
        let totalPaymentConcile = 0;


         installments.map((value:any) => {


            value?.payments?.forEach((value2:any)=>{
  
              if(value2.payment.reconciled_payment){
  
                totalPaymentConcile = this.toFixedApp(totalPaymentConcile +  value2.payment.amount);
  
              }
  
            });
          value["totalpaymentReference"] = 0;
  
          value?.paymentReference?.forEach((value2:any) =>{
            value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
          });


          if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !installmentNexPayment){
            installmentNexPayment = value?.installMent;
          }


          if(value?.installMent?.payment_status?.code != 'PaymentComplete' &&  value?.expired){
            this.mounto_pendiente_vencido = this.toFixedApp(this.mounto_pendiente_vencido + value.installMent.dueBalance) ;
          }

          if(value?.installMent?.payment_status?.code != 'PaymentComplete'){
            this.mounto_pendiente =this.toFixedApp( this.mounto_pendiente + value.installMent.dueBalance) ;
          }
          

          
          
          return value;
        });

        invoice.installMent = installments;
        invoice.installmentNexPayment = installmentNexPayment;
        invoice.totalPaymentConcile = totalPaymentConcile;

        
        return invoice;

      });

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }




  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {
          code_person :this._auth.sessionUser?.person_code
         
         }

        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
          console.error(error);
        
        
      }
  }



  getSection_transaction_by_person = async (buro:any = true) => {

    try {

    const resp = await this._user.getSection_transaction_by_person({ code: this._auth.sessionUser?.person_code,type:'ONBOARDING' });

    const result = resp.resp;
    let temp: any = {};

    if (result?.section_transactions?.length > 0) {

      result?.section_transactions.map((obj: any) => {

        temp[obj?.section_transaction?.code] = obj;

        if (obj?.section_transaction?.code == "BURO" && obj.condition == "COMPLETE" && buro) {
            this.getDataBuro(this.person, false);
          }
        return obj;
      });


    }

    
    this.section_transaction = temp;
    this.transaction = result?.transaction;

    this.loading = false;

   
  } catch (error) {
    this.loading = false;

    console.log(error);
    
      
  }
  }


  getDataBuro = async (item: any, reload = true) => {
    this.loading_buro = true;

    try {

         if (item?.identitys?.length > 0) {
      let cde = item?.identitys[0]?.code_identity?.code == 'V'? 1: 3;
      let params = {
        "type_identity": cde,
       // "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      const buro = await this._user.getDataBuro(params);
      this.loading_buro = false;

      this.buro = buro?.resp;


      if (reload) {
        this.getSection_transaction_by_person(true);
      }


    }

        
    } catch (error) {
        this.loading_buro = false;
  
    }
   
  }





  getSectionTransactions = async () => {

    let filter ={type:'ONBOARDING'}

    const resp = await this._user.getSectionTransactions(filter);
    this.section_transactions = resp.resp;



  }
  

  toogleStatus = async (st = this.usQuote) => {
    
    let status = st?true:false;


    try {
      let query = {status};

      const resp = await this._user.toogleStatus(query);

      this.person.person = resp.res;
      this.usQuote = resp.res.quota_client_active;

    } catch (error) {
      this.usQuote = !status;
      console.log(error);
      
    }


  }

  bard(a:any,b:any){

   
    let resto =  this.toFixedApp( this.toFixedApp( this.toFixedApp(a) *  this.toFixedApp(100)) /  this.toFixedApp(b));
    let result =   this.toFixedApp(this.toFixedApp(100 )- this.toFixedApp((resto)));
    return result;
  }

  toFixedApp = (x:any) => {

    let a:any = `e+${2}`;
    let b:any = `e-${2}`;
  
    return +(Math.round(x + a) + b);
  
    //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
    
  }

}

