import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-cupo',
  templateUrl: './active-cupo.component.html',
  styleUrls: ['./active-cupo.component.css']
})
export class ActiveCupoComponent implements OnInit {

  queryParams:any;
  constructor(

    public router: Router,
    private activatedRoute: ActivatedRoute
    
    ){



    }


  ngOnInit(): void {
    localStorage.setItem('active_cupo',"true");
    this.router.navigate([environment.loginRoute], {} );

  }

}
