

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import * as alertifyjs from 'alertifyjs';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-contact-onboarding',
  templateUrl: './contact-onboarding.component.html',
  styleUrls: ['./contact-onboarding.component.css'],
  providers: [DatePipe]

})
export class ContactOnboardingComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   biometria = false;
   imgPerson:any
start_psicometrico:any;
view:any = 1;

  isAuth:any;
  sessionUser:any;

start_psicometrico_load = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private offcanvasService: NgbOffcanvas,
    public _auth : AuthService


  ) { }

  ngOnInit(): void {
    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson();
      }
    });


    this.socketService.resetDataClientCode.subscribe((person_code: any) => {

      if(person_code && person_code == this.person?.person.code){

        this.getSection_transaction_by_person();

      }
    });
    


  }

  getPerson = async () => {
    this.loading = true;

    const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
    this.person = resp.resp;
    this.imgPerson = this.person?.person?.file_selfie?.url;
    this.loading = false;

    if (this.person?.person) {

      this.getSectionTransactions();
      this.getSection_transaction_by_person();
    }


  }


  getSection_transaction_by_person = async (buro:any = true) => {

    try {

    const resp = await this._user.getSection_transaction_by_person({ code: this._auth.sessionUser?.person_code,type:'ONBOARDING' });

    const result = resp.resp;
    let temp: any = {};

    if (result?.section_transactions?.length > 0) {

      result?.section_transactions.map((obj: any) => {

        temp[obj?.section_transaction?.code] = obj;

        if (obj?.section_transaction?.code == "BURO" && obj.condition == "COMPLETE" && buro) {
          this.getDataBuro(this.person, false);
        }
        return obj;
      });


    }

    
    this.section_transaction = temp;
    this.transaction = result?.transaction;
   
  } catch (error) {

    console.log(error);
    
      
  }
  }




  getSectionTransactions = async () => {

    let filter ={type:'ONBOARDING'}

    const resp = await this._user.getSectionTransactions(filter);
    this.section_transactions = resp.resp;



  }

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;




  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  getDataBuro = async (item: any, reload = true) => {
    this.loading_buro = true;

    if (item?.identitys?.length > 0) {
      let cde = item?.identitys[0]?.code_identity?.code == 'V'? 1: 3;
      let params = {
        "type_identity": cde,
       // "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      const buro = await this._user.getDataBuro(params);
  
      this.buro = buro?.resp;

      if(this.buro?.status_approved == 'WAIT_PSICOMETRICO' ){

       
       this.getScorePsicometrico(item,params);

        let d = new Date(this.buro?.consult_psicometrico_date);
        let dNew =   new Date();
        
          if( dNew > d ){
          this.start_psicometrico = true;

        }

      }

      this.person.person = this.buro?.person;

      this.setResponseBuro(this.buro);
      this.loading_buro = false;

      if (reload) {
        this.getSection_transaction_by_person(true);
      }


    }

  }



  send_responsea_approvated = async () => {
    this.loading_buro = true;

    try {
      

        let params = {
          "code_person": this._auth.sessionUser?.person_code
        }
       
     //   const response = await this._user.send_responsea_approvated(params);
     // alertifyjs.success(`Mensaje enviado exitosamente`);
  
    
        this.loading_buro = false;
  
    } catch (error) {
      console.log(error);
      
    }


  }
  

  


  getDataBuroSimple = async (item: any, reload = true) => {
    this.loading_buro = true;

    if (item?.identitys?.length > 0) {

      let cde = item?.identitys[0]?.code_identity?.code == 'V'? 1: 3;

      let params = {
       // "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "type_identity": cde,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      const buro = await this._user.getDataBuro(params);
  
      this.buro = buro?.resp;

      this.person.person = this.buro?.person;

      this.setResponseBuro(this.buro);
      this.loading_buro = false;

    }

  }

  
  setResponseBuro(resp: any) {

    const { status_approved, type_approved } = resp;


    this.status_approved = status_approved;
    this.type_approved = type_approved;
  
      
  }

  public redirect = () => {
    /*
      data = {
        firstName: "juan",
        lastName: "soto",
        dateOfBirth: "02/06/1999",
        gender: "male",
        mobile: "0404040404",
        email: "juan@kyryll.com"
      }
    */

    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }

    // if(!(item?.constacts?.length > 0))
    // return "";

    // return `${item?.constacts[0]?.contact}`;


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }


  getScorePsicometrico = async (item: any, params2:any) => {
    try {
      if (item?.identitys?.length > 0) {
        const params = {
          "DocID": item?.identitys[0]?.identity,
          //"DocID":"11314836",
          "TypeID": item?.identitys[0]?.code_identity?.code,
          "ClieID": environment.ClieID,
          "IP": environment.IP_LOCAL,
          "code_person":this.person?.person?.code
        };
        let psicometrico = await this._user.getScorePsicometrico(params);

        let status = psicometrico?.res?.status_approved

        if(status != 'WAIT_PSICOMETRICO'){

         this.getDataBuroSimple(item,params2);
         
        }
        
       /*  psicometrico = 
        
       {"Success":[
          {"Cedula":"V10726319",
          "pcs":"553",
          "Social_Desirability":"160",
          "Skills":"189",
          "Materialism":"125",
          "Confidence":"290",
          "Debt_attitude":"119",
          "Gratification":"227",
          "isTooFast":"0"}]
        };*/

/*
      if(psicometrico?.res?.Success){
        let sum =0;

      let {Cedula,...psicometrico_resp} = psicometrico?.res?.Success[0];
      this.psicometrico = psicometrico_resp;

      let psicometrico_array:any=[];
      for(let key in psicometrico_resp){
        if(this.psicometrico.hasOwnProperty(key)) {
         // console.info(key + ': ' + this.psicometrico[key]);

         psicometrico_array = [...psicometrico_array,{key,value:this.psicometrico[key] || 0}];
          sum = sum + parseFloat(this.psicometrico[key] || 0);
        
      }

      }

      this.psicometrico_array = psicometrico_array;

      this.psicometrico_response = sum ;
      this.showActivatePsico = false;
      
      }else{

        this.showActivatePsico = true;

      }


        */

      }
    } catch (error) {

      console.error(error);

    }

    this.start_psicometrico_load = false;

  }



  setStatus_psicometrico = async (active_psicometrico:boolean) => {

    const resp = await this._user.setStatus_psicometrico({ code: this._auth.sessionUser?.person_code,active_psicometrico });
    this.person.person = resp.resp;
    this.imgPerson = this.person?.person?.file_selfie?.url;
    this.activatePsico = active_psicometrico;

  }

  sendteminosClient = async () => {

  /*
      let msg= `Creditotal. Terminos ${environment.BASE_FORNT2}/verification/approve-conditions/${this.queryParams?.code}`
      let number = this.getContact(this.person);
    
      try {
    
        const data =  {
            number,
            msg
          }
      
            //  await this._user.send_whatsapp(data);
              this.sendNoteClient(msg,number);
    
            //  alertifyjs.success(`Mensaje enviado exitosamente`);
    
    
    
      } catch (error) {
        console.error(error);
        
        
      }
      
    */
  
  }

  
sendNoteClient = async (msg:any,phone:any) => {
    
  try {
    const data =  {
        phone,
        msg,
        "client": "CrediTotal"
      }
  
          let result = await this._user.sendsmsGeneral(data);
          alertifyjs.success(`Mensaje enviado exitosamente`);

            if (!(result?.error)) {
  
  
          }
  
  } catch (error) {
    console.error(error);
    
    
  }

}

setBiometria(param:any){
  
  this._user.setBiometria({type:param,person:this.person?.person?.code});
  
}




redirectIMG(url:String){
  const link = `${url}`;
  window.open(link, '_blank');

}


pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

  try {
  
      this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );
  
  } catch (error) {
    console.log(error);
    
  }
     
}

offcanvasServiceClose(){
  this.view = 1;
  this.offcanvasService.dismiss();

}

setChangView(view:any){
  this.view = view;
  this.getSection_transaction_by_person();

}


}
