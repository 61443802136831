
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { log } from 'console';

@Component({
  selector: 'approve-conditions-account',
  templateUrl: './approve-conditions-account.component.html',
  styleUrls: ['./approve-conditions-account.component.css']
})

export class ApproveConditionsAcountComponent implements OnInit {

  @Output() reset = new EventEmitter<any>();

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  loading_term2:any = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;
  terms_and_conditions:any;
  terms_and_conditions_arr: any = [];
  customerPendingTAC:any;
  isAuth:any;
  sessionUser:any;
  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   hidenApro = true;
   acept:any = false;
   msg:any;



   
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public _auth : AuthService


  ) {         
  }

  ngOnInit(): void {
    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson()
      }
    });

/*
    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this._auth.sessionUser?.person_code) {
        this.getPerson();
      }else{
        this.rdrMarca();

      }

    });*/

  }


  getCustomerPendingTAC = async () => {
    this.loading = true;


    try {
      let res:any = await this._user.getCustomerPendingTAC({ code_person: this._auth.sessionUser?.person_code });
      
      console.log(res);
      let accounts = res.resp;

      this.customerPendingTAC = accounts[0];
      this.terms_and_conditions_arr = res.resp;


      if(accounts?.length > 0 ){
        // let bank = accounts[0].bank;
        console.log(this.person);
        let body = {
          bank: accounts[0].bank,
          obj: {
            "nombre_completo": this.capitalizarPrimeraLetra(accounts[0].customer.name) + ' ' + this.capitalizarPrimeraLetra(accounts[0].customer.lastName),
            "nro_cuenta": accounts[0].account_number,
            "identity": this.person['identitys'][0].identity,
            "nacionalidad": this.person['identitys'][0].code_identity.description,
            "estado_civil": this.person?.person?.marital_status?.name
          }
        }
        debugger;
        const resp = await this._user.postDirect_debits_terms_and_conditions(body);

        // const resp = await this._user.getDirect_debits_terms_and_conditions({ bank: bank?._id });

        this.terms_and_conditions = resp.res;
        
        this.acept = false;
        this.hidenApro = false;
        this.loading = false;

      }else{

        this.acept = true;


      }

    } catch (error) {
      console.error(error);
      this.loading = false;

    }

  }
  
  capitalizarPrimeraLetra = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getPerson = async () => {
    this.loading = true;

    
    try {
      const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code  });
      this.person = resp.resp;
      this.getCustomerPendingTAC();
      if(!(this.person?.person) ){

       // this.rdrMarca();

      }else{

        this.acept = false;
        this.hidenApro = false;
        this.loading = false;

      }

    } catch (error) {
      this.rdrMarca();
      console.error(error);
      this.loading = false;

    }

  }
  

  personApproveConditionsPost = async () => {
      try {


        this.loading_term2 = true;

      const data =  {

        payment_method_customer :this.customerPendingTAC?._id,
        terms_and_condition :this.terms_and_conditions?._id
        
      }

     let result = await this._user.payment_method_customerPost(data);
      if (!(result?.error)) {

        
        

            this.msg = true;
            alertifyjs.success('Aceptación exitosa');
            this.acept = true;

           

            /*
            try {


            const query =  {

              "code_person":this._auth.sessionUser?.person_code,
              "increment_type":"",
              "amount":10
              
            }

            let result = await this._user.setUpdate_quote(query);

            } catch (error) {

              console.log(error);
              
            }*/
            this.loading_term2 = false;

            if(this.terms_and_conditions_arr.length>1) {
              this.getPerson();
              this.getCustomerPendingTAC();
            } else {
              this.reset.emit(true);
            }

            

            


           // this.rdrMarca();

           
   }


  } catch (error) {
    console.error(error);
    
    
  }

}



rdrMarca(){
  window.location.href = environment.BASE_FORNT;

}


getContact(item: any) {

  if (!(item?.constacts?.length > 0))
    return "";

  return `${item?.constacts[0]?.contact}`;


}

getIdentity(item: any) {

  if (!(item?.identitys?.length > 0))
    return "";

  return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
