<div class="container mt-2  mt-md-4">

    <ng-container *ngIf="!loading">



        <!-- <div class="card mt-3">

            <div class="card-body">
                 <div class=" d-flex text-dark ">
                    <div class="mb-0  w-100">
                        <div class="d-flex justify-content-between">



                            <div>
                                <div class="h5 fw-bold">
                                </div>
                                <div class=" d-flex text-dark ">
                                    <div class="mb-0  w-100">
                                        <div class="d-flex justify-content-between">

                                            <div [ngClass]="{'text-success': person?.person?.quota_active,'text-secondary': !person?.person?.quota_active}">
                                                {{person?.person?.quota_active? 'Disponible':'Bloqueado'}}

               </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="">
                                boton
                            </div>

                        </div>

                    </div>
                </div> 


                
                <div class=" d-flex text-dark mt-3 " *ngIf="person?.person?.quota_active">
                    <div class="mb-0  w-100">
                        <div class="d-flex justify-content-between">

                            <div>
                                Habilitado
                                <div class="small fw-normal text-secondary" *ngIf="person?.person?.quota_client_active">
                                    Activado <span>{{person?.person?.date_quota_client_active | date: 'dd/MM/yyyy h:mm:ss a'}} </span>
                                </div>
                            </div>



                            <div>
                                <ng-toggle (change)="toogleStatus()" [(ngModel)]="usQuote" [value]="true" [color]="{
                                    checked: '#56C128',
                                    unchecked: '#dcdcdc'
                                }"></ng-toggle>


                            </div>
                        </div>

                    </div>
                </div> 

            </div>

        </div> -->
        <!-- 
        <app-contact-onboarding>

        </app-contact-onboarding> -->


        <div class="text-center mt-4 ">
            Monto disonible para comprar

            <h3 class="text-dark fw-bold mt-2  mb-0">
                {{ quota?.disponible}} USD
            </h3>
        </div>

        <div class="mt-4">

            <div class="d-flex">

                <div (click)="tp = 1" [ngClass]="{'fw-bold': tp == 1}" class=" cursor-pounter text-primary" style="width: 100px;">
                    Activos
                </div>
                <div (click)="tp = 2" [ngClass]="{'fw-bold': tp == 2}" class="cursor-pounter text-primary" style="width: 100px;">
                    Completados
                </div>
            </div>
            <hr class="pt-0 mt-1">

        </div>

        <ng-container *ngIf="tp==1">

            <div *ngFor="let item of invoiceCredits">


                <div class="card  cmt2 mt-4" *ngIf="item?.payment_status?.code != 'PaymentComplete'" [routerLink]="['/invoice-detail/',item?.code]">
                    <div class="card-body ">
                        <div class="mt-2 ">

                            <div class="d-flex w-100 ">
                                <div>
                                    <span class="ms-2 fw-bold  ">{{shops_obj[item?.provider_transaction?.shop]}}</span>

                                </div>
                                <div class="text-end ms-auto ">
                                    <span class="text-dark fw-bold  fz-18 ">{{item?.totalAmount}} USD</span>
                                </div>
                            </div>

                        </div>


                        <div class="mt-2 ">
                            <div class="d-flex w-100 ">
                                <div class=" ">
                                    <span class=" text-secondary ps-2  ">{{item?.date  | date:'d MMM, yyyy':'es'}} </span>
                                </div>
                            </div>

                            <div class="mt-3">
                                <ngb-progressbar type="info" [value]=" bard(item.dueBalance,item.totalAmount)">Copying file <b>{{ bard(item.dueBalance,item.totalAmount)}}%</b>...</ngb-progressbar>

                            </div>

                        </div>



                    </div>
                </div>


            </div>


        </ng-container>

        <ng-container *ngIf="tp==2">

            <div *ngFor="let item of invoiceCredits">

                <div class="card  cmt2 mt-4" *ngIf="item?.payment_status?.code == 'PaymentComplete'" [routerLink]="['/invoice-detail/',item?.code]">
                    <div class="card-body ">
                        <div class="mt-2 ">

                            <div class="d-flex w-100 ">
                                <div>
                                    <span class="ms-2 fw-bold ">{{shops_obj[item?.provider_transaction?.shop]}}</span>

                                </div>
                                <div class="text-end ms-auto ">
                                    <span class="text-dark fw-bold fz-18 ">{{item?.totalAmount}} USD</span>
                                </div>
                            </div>

                        </div>


                        <div class="mt-2 ">
                            <div class="d-flex w-100 ">
                                <div>
                                    <span class=" text-secondary ps-2 ">{{item?.date  | date:'d MMM, yyyy':'es'}} </span>

                                </div>
                                <div class="text-end ms-auto ">
                                    <span class=" text-secondary ">Finalizado </span>
                                </div>
                            </div>



                        </div>



                    </div>
                </div>


            </div>

        </ng-container>






    </ng-container>

</div>

<ng-container *ngIf=" loading || loading_buro ">
    <div class="w-100 text-center ">
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>