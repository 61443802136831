


import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-approve-conditins-view',
  templateUrl: './approve-conditins-view.component.html',
  styleUrls: ['./approve-conditins-view.component.css']
})
export class ApproveConditinsViewComponent implements OnInit {


  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;
  

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   hidenApro = true;
   acept:any = true;
   msg:any;
  constructor(
  
    private sanitizer: DomSanitizer,


  ) { }

  ngOnInit(): void {


  }



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
