import { Component, OnInit } from '@angular/core';
import {Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    public _auth:AuthService
  ) { 
    
    this._auth.logout();
    this.router.navigate([environment.loginRoute], {});

  }

  ngOnInit(): void {


   

  }

}
