

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-contact-financial-payments',
  templateUrl: './contact-financial-payments.component.html',
  styleUrls: ['./contact-financial-payments.component.css'],
  providers: [DatePipe]

})
export class ContactFinancialPaymentsComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installments: any = [];
   installmentNexPayment :any;
view = 1;
invoiceSelected:any;
installMentSelected:any;
payment:any;
isAuth:any;
sessionUser:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,

  ) { }

  ngOnInit(): void {
    this.sessionUser = this._auth.sessionUser;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        this.getPerson()
      }
    });


  }

  getPerson = async () => {
    
    this.loading_person = true;
    try {

      const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
      const resp_invoiceCredits = await this.listClientInvoiceCredits();
 
      this.person = resp.resp;
      this.resp_invoiceCredits = resp_invoiceCredits?.res;

      
    } catch (error) {
      console.error(error);

    } 

  
    this.loading_person = false;


  }


  listClientInvoiceCredits = async () => {
       
    try {
         const data =  {
          code_person :this._auth.sessionUser?.person_code
         
         }

        return this._user.listClientInvoiceCredits(data);

      } catch (error) {
        console.error(error);
        
        
      }
  }



  getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
    this.loading = true;

    this.invoiceSelected = invoice;
    try {
         const data =  {
          code_invoice:invoice?.code 
         
         }

       const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

       this.installments = installments?.res;

       this.installments.map((value:any, index:any) => {
        if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !this.installmentNexPayment){

          this.installmentNexPayment = value?.installMent?.code;

        }

        value["totalpaymentReference"] = 0;

        value?.paymentReference?.forEach((value2:any) =>{
          value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
        });
        


        return value;
      });

      

/*
      this.installments.sort((a:any, b:any) => {
        let temp = a?.installMent?.order;
        let temp2 = b?.installMent?.order;
    
        return  temp - temp2;
      });*/
      
       
       this.view = 2;

      } catch (error) {
        console.error(error);
        
      }

      this.loading = false;

  }


  getPersonInstallmentpayment = async (installMentSelected:any) => {
       
    this.loading = true;

    this.installMentSelected = installMentSelected;
    try {
         const data =  {
          code_installMent:installMentSelected?.code 
         
         }

       const  payment  = await this._user.getPersonInstallmentpayment(data);

       this.payment = payment?.res;
       this.view = 3;

      } catch (error) {
        console.error(error);
        
        
      }

      this.loading = false;

  }
  

  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getNumberIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  closeInstallment(){

    if(this.view == 2 ){
      this.installments = [];
      this.view = 1;
      this.invoiceSelected=null;
      this.installmentNexPayment = null;

    }else  if(this.view == 3 ){
      this.view = 2;
      this.installMentSelected = null;
      this.payment = null;
      this.installmentNexPayment = null;
   
    }
   
         }

restClient = async () =>  {

  if (this.person?.identitys?.length > 0) {
    this.loading = true;

    const data:any = this.person?.identitys[0];

    let params = {
    
      "client": {
        "type_identity": data.type_identity?.code,
        "code_identity": data.code_identity?.code,
        "identity": data.identity
      }
    
    };

  try {
       
    let resp = await this._user.clientQuotaReset(params);

    this.socketService.setResetstadistic(true);

    this.installments = [];
    this.resp_invoiceCredits = [];
    this.invoiceSelected=null;
    this.invoiceSelected=null;
    this.installMentSelected = null;
    this.payment = null;
    this.view = 1;


    this.getPerson();


   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
        }

   }
   this.loading = false;

}
}



redirectTo() {
  this.router.navigate([`/console/payments/person/${this.person?.person?.code}`], {});
}

setIdentity(){
  localStorage.setItem('identity',this.getNumberIdentity(this.person));

}

pagar(invoiceSelected:any,installMent:any){

  let url = '/payment/'+invoiceSelected+'/'+installMent;
  this.router.navigate([url], {});

}


}
