import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
@Injectable()
export class IdentGuard implements CanActivate {

    constructor(private router: Router, private _auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._auth.ident) {
            return true;
        } else {
            this.router.navigate([environment.redirectRoute], {});
            return false;
        }
    }
}
