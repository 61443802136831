

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MbscScrollViewOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LaService } from 'src/app/modules/la/servises/la.service';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe],

})
export class PaymentsComponent implements OnInit {


  scrollViewOptions: MbscScrollViewOptions = {
    layout: 'fixed',
    itemWidth: 300,
    snap: false
};

payment:any;

quota:any = {total:50,disponible:0,utilizado:0};
mounto_pendiente_vencido:any = 0;
mounto_pendiente:any = 0;
shops:any = [];
shops_obj:any = {};
resp_paymentsCredits:any;


buro: any;
loading_buro = false;

  loading: any = true;
  isAuth: any;
  sessionUser: any;
  person: any;
  imgPerson: any;
  usQuote: any = false;

  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  installments:any;
  installmentNexPayment:any;
  invoiceCredit:any;
  tp:any = 1;

  invoiceCredits:any = [];
  queryParams:any={};

  private socketServiceSubscribe:any  = Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,
    private offcanvasService: NgbOffcanvas,


  ) {
    
  }

  

  ngOnDestroy(): void {
    this.socketServiceSubscribe.unsubscribe();
  }

  ngOnInit(): void {

        this._auth.auth$.subscribe((rep: any) => {
          this.isAuth = rep;
          if (rep) {
            this.getPerson();
          }
        });
      
      


    this.sessionUser = this._auth.sessionUser;

  
    
  

   this.socketServiceSubscribe =  this.socketService.msgDataClient.subscribe((payload: any) => {

      if(payload?.code_person == this._auth?.sessionUser?.person_code  && payload?.type ==  'CREATE_INVOICE'){
       this.getPerson(false);
       }
       
     });

     this.getshops();
  }




getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


  getPerson = async (loadinge = true) => {

    try {
    
    const resp = await this._user.getPersonbyCode({ code: this._auth.sessionUser?.person_code });
    this.person = resp.resp;



      const resp_paymentsCredits = await this.listClientPaymentsCredits();
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

      
      this.loading = false;
  
    this.imgPerson = this._auth.sessionUser?.file_selfie?.url;
      this.usQuote =  this.person?.person?.quota_client_active ? true:false;

    } catch (error) {
      console.error(error);
      this.loading = false;

    }
  }

  


  
  listClientPaymentsCredits = async () => {
         
    try {
         const data =  {
          code_person : this._auth.sessionUser?.person_code
         
         }

        return this._user.listClientPaymentsCredits(data);

      } catch (error) {
        console.error(error);
        
        
      }
  }


  bard(a:any,b:any){



   
let resto =  this.toFixedApp( this.toFixedApp( this.toFixedApp(a) *  this.toFixedApp(100)) /  this.toFixedApp(b));
let result =   this.toFixedApp(this.toFixedApp(100 )- this.toFixedApp((resto)));
return result;
  }

  toFixedApp = (x:any) => {

    let a:any = `e+${2}`;
    let b:any = `e-${2}`;
  
    return +(Math.round(x + a) + b);
  
    //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
    
  }
  
  restab(a:any,b:any){

    return  this.toFixedApp( a - b);

  }

  openview(content: TemplateRef<any>,data:any) {


    this.payment = data;
    
    this.offcanvasService.open(content, { position: 'start', keyboard: false });


  
  }

}
