<div class="mt-md-3 p-3">
    <ng-container class="mt-2">
        <div class=" col-12  ">

            <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
                <p style="background: transparent;margin-bottom: 0cm;line-height: 100%;text-align: center;"><span style="color: rgb(0, 0, 0);"><strong>T&eacute;rminos y Condiciones</strong></span></p>
                <p style="background: transparent;margin-bottom: 0cm;line-height: 100%;text-align: center;"><span style="color: rgb(0, 0, 0);"><strong>Plan de Venta con Financiamiento de Mundo Total</strong></span></p>
                <p style="background: transparent;margin-bottom: 0cm;line-height: 100%;text-align: center;"><span style="color: rgb(0, 0, 0);"><strong>CrediTotal</strong></span></p>
                <p style="background: transparent;margin-left: 1.27cm;text-indent: -1.27cm;margin-bottom: 0cm;line-height: 100%;text-align: justify;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><strong>Alcance</strong></span></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">Lea cuidadosamente estos t&eacute;rminos y condiciones (&ldquo;</span></span><span style="color: rgb(0, 0, 0);"><strong>T&eacute;rminos y Condiciones</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;) antes de presentar su solicitud de adquisici&oacute;n de cualquier producto de [Denominaci&oacute;n Social Mundo Total] (</span></span><span style="color: rgb(0, 0, 0);"><strong>&ldquo;Mundo Total&rdquo; o &ldquo;la Compa&ntilde;&iacute;a&rdquo;</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">) bajo el Plan de Venta con Financiamiento &ndash; CrediTotal (&ldquo;</span></span><span style="color: rgb(0, 0, 0);"><strong>Plan</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;).&nbsp;</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">Los presentes T&eacute;rminos y Condiciones regulan las ventas que Mundo Total realice con usted como cliente (</span></span><span style="color: rgb(0, 0, 0);"><strong>&ldquo;usted&rdquo;</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">,</span></span><span style="color: rgb(0, 0, 0);"><strong>&nbsp;&ldquo;Cliente&rdquo; o &ldquo;Comprador&rdquo;</strong></span><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">), siempre que haya aplicado y adquirido los productos comercializados por la Compa&ntilde;&iacute;a bajo la modalidad del Plan.&nbsp;</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">El Plan es un programa de venta con financiamiento dise&ntilde;ado y ofrecido por Mundo Total a sus Clientes en los establecimientos escogidos por Mundo Total (&ldquo;</span></span>
                    <span style="color: rgb(0, 0, 0);"><strong>Tiendas Habilitadas</strong></span><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;). A trav&eacute;s del Plan, los Clientes que hayan aprobado el proceso de selecci&oacute;n y evaluaci&oacute;n (&ldquo;</span></span>
                    <span style="color: rgb(0, 0, 0);"><strong>Proceso de Selecci&oacute;n</strong></span><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;) dise&ntilde;ado por Mundo Total (&ldquo;</span></span>
                    <span style="color: rgb(0, 0, 0);"><strong>Clientes Seleccionados</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;), podr&aacute;n adquirir productos comercializados por Mundo Total (&ldquo;</span></span><span style="color: rgb(0, 0, 0);"><strong>Productos</strong></span>
                    <span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;), pagando el precio de contado, una parte en dinero mediante los medios de pago aceptados por Mundo Total (p.ej., efectivo, transferencias bancarias, pago m&oacute;vil, operaciones en punto de venta, entre otros), y la otra parte mediante la entrega de pagar&eacute;s, sujeto a las condiciones descritas en la secci&oacute;n III de estos T&eacute;rminos y Condiciones. &nbsp;</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">Al aceptar estos T&eacute;rminos y Condiciones, usted expresa su total, claro e inequ&iacute;voco entendimiento, consentimiento y acuerdo de estos T&eacute;rminos y Condiciones, sin reserva alguna. Los T&eacute;rminos y Condiciones forman parte integrante y vinculante de cualquier acuerdo, oferta u operaci&oacute;n comercial que usted realice bajo el Plan. Si usted no est&aacute; de acuerdo con los T&eacute;rminos y Condiciones en su totalidad o en parte de ellos, no debe utilizar ni aplicar al Plan. Si tiene alguna pregunta o inquietud relacionada con los T&eacute;rminos y Condiciones puede ponerse en contacto con nosotros a trav&eacute;s de [insertar email Mundo Total].</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">Al participar en el Plan, usted recibir&aacute; v&iacute;a mensaje de texto (SMS) un enlace al texto completo de estos T&eacute;rminos y Condiciones que han sido aceptados y consentidos por usted. Adicionalmente, los T&eacute;rminos y Condiciones se encuentran disponibles en la p&aacute;gina web de Mundo Total en el siguiente enlace [insertar enlace].</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><strong>Funcionamiento del Plan</strong></span></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">El Plan consiste en una venta de contado, en la cual los Cliente Seleccionados podr&aacute;n adquirir los Productos pagando el precio de compra (i) al menos 50% en dinero (p.ej., efectivo, transferencias bancarias, pago m&oacute;vil, operaciones en punto de venta, plataformas de pago, entre otros), y (ii) hasta 50% en especie, mediante la emisi&oacute;n y entrega a Mundo Total de uno o m&aacute;s pagar&eacute;s con vencimiento de hasta quince (15) d&iacute;as continuos, que causan novaci&oacute;n (&ldquo;</span></span>
                    <span style="color: rgb(0, 0, 0);"><strong>Pagar&eacute;s</strong></span><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">&rdquo;). Cada operaci&oacute;n bajo el Plan se ajustar&aacute; a los par&aacute;metros, plazos y montos m&aacute;ximos establecidos unilateralmente por Mundo Total.&nbsp;</span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-weight: normal;">Mundo Total seleccionar&aacute; discrecional y unilateralmente los Productos que podr&aacute;n ser adquiridos por los Clientes Seleccionados bajo la modalidad del Plan. Mundo Total informar&aacute; a los Clientes los Productos disponibles del Plan a trav&eacute;s de cualquier medio de comunicaci&oacute;n (</span></span>
                    <span style="color: rgb(0, 0, 0);"><em><span style="font-weight: normal;">v.gr.</span></em>
                    </span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">, habladores, cat&aacute;logos, etc.). Los productos que Mundo Total tenga en oferta o promoci&oacute;n en las Tiendas Habilitadas no formar&aacute;n parte del Plan.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total determinar&aacute; referencialmente el Precio M&aacute;ximo de Venta al P&uacute;blico (&ldquo;</span></span>
                    </span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>PMVP</strong></span></span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">&rdquo;) de todos los Productos en USD. Las facturas ser&aacute;n expresadas en Bol&iacute;vares al tipo de cambio de referencia publicado por el Banco Central de Venezuela del d&iacute;a de la emisi&oacute;n de la factura.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total se reserva el derecho a modificar los precios de los Productos objeto del Plan que a&uacute;n no hayan sido entregados o adquiridos por los Clientes Seleccionados.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Los Clientes Seleccionados podr&aacute;n efectuar la compra de los Productos hasta por el monto total aprobado por Mundo Total, dentro de los tres (3) d&iacute;as continuos siguientes de haber recibido la notificaci&oacute;n de su aprobaci&oacute;n. A tal efecto, los Clientes deber&aacute;n presentarse personalmente en las Tiendas Habilitadas para activar la aprobaci&oacute;n del Plan mediante la exhibici&oacute;n a un Promotor del c&oacute;digo de aprobaci&oacute;n recibido de Mundo Total a trav&eacute;s del mensaje de texto (SMS) y/o correo electr&oacute;nico.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Las pol&iacute;ticas de devoluciones, garant&iacute;as y condiciones de los productos comercializados por Mundo Total son aplicables a todas las compras realizadas por los Clientes bajo el Plan. Para su referencia, en el siguiente enlace podr&aacute; acceder a dichas pol&iacute;ticas [insertar enlace Mundo Total].</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">A los fines de participar y optar por la adquisici&oacute;n de productos bajo el Plan, los Clientes que se encuentren en las Tiendas Habilitadas a tal fin deber&aacute;n dirigirse a un promotor de ventas de Mundo Total (</span></span>
                    </span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>&ldquo;Promotor&rdquo;</strong></span></span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">) y manifestarle su voluntad expresa de aplicar al Proceso de Selecci&oacute;n explicado en estos T&eacute;rminos y Condiciones.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total entregar&aacute; al Cliente Seleccionado los Productos adquiridos bajo el Plan al momento de su pago, en los t&eacute;rminos descritos anteriormente. Adicionalmente, Mundo Total entregar&aacute; al Cliente Seleccionado copias de los Pagar&eacute;s firmados conjuntamente con la factura fiscal correspondiente. A partir de la entrega de los Productos, el Cliente Seleccionado ser&aacute; el &uacute;nico responsable por cualquier da&ntilde;o o p&eacute;rdida de dichos Productos adquiridos bajo el Plan.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">El Cliente Seleccionado deber&aacute; pagar los Pagar&eacute;s en la fecha de su vencimiento en D&oacute;lares de los Estados Unidos o en su equivalente en Bol&iacute;vares el tipo de cambio de referencia publicado por el Banco Central de Venezuela y vigente para la fecha de pago del Pagar&eacute;. El Cliente Seleccionado podr&aacute; pagar total o parcialmente los Pagar&eacute;s antes de la fecha de vencimiento, sin penalidad alguna. Los Pagar&eacute;s no generar&aacute;n intereses compensatorios y Mundo Total no cobrar&aacute; comisiones ni recargos por la adquisici&oacute;n de los Productos bajo el Plan.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Los Clientes Seleccionados deber&aacute;n reportar inmediatamente el pago de los Pagar&eacute;s, bien sea personalmente en las Tiendas Habilitadas o a trav&eacute;s de [&bull;].</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total mantendr&aacute; informado a los Clientes Seleccionados que adquieran Productos bajo el Plan, sobre las fechas de pago y montos adeudados a trav&eacute;s de llamadas, mensajes de texto (SMS) y/o correos electr&oacute;nicos en el n&uacute;mero de tel&eacute;fono m&oacute;vil o correo electr&oacute;nico suministrado en la solicitud del Plan. Usted acepta recibir dichos mensajes sin limitaci&oacute;n.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">En caso que el Cliente Seleccionado incumpla con sus obligaciones de pago de los Pagar&eacute;s, todos los montos adeudados por el Cliente Seleccionado se considerar&aacute;n de plazo vencido, l&iacute;quidas y exigibles. Adicionalmente, el Cliente Seleccionado deber&aacute; a Mundo Total intereses de mora sobre dichos saldos deudores a la tasa del inter&eacute;s legal de conformidad con lo previsto en el C&oacute;digo Civil venezolano, calculados desde la fecha de vencimiento del Pagar&eacute; hasta su fecha efectiva de pago por parte del Cliente Seleccionado.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>Proceso de Selecci&oacute;n&nbsp;</strong></span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Usted se compromete a suministrar a Mundo Total de manera cierta y correcta la informaci&oacute;n correspondiente a su perfil personal y crediticio para que su solicitud de aplicaci&oacute;n al Plan pueda ser evaluada a trav&eacute;s del Proceso de Selecci&oacute;n. El Proceso de Selecci&oacute;n se regir&aacute; por las siguientes reglas:&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Solo podr&aacute;n participar en el Plan los Compradores que: (i) sean mayores de dieciocho (18) a&ntilde;os; (ii) tengan c&eacute;dula de identidad laminada y vigente; y (iii) tengan un tel&eacute;fono celular con una l&iacute;nea telef&oacute;nica activa.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">La selecci&oacute;n de los Clientes que podr&aacute;n optar al Plan no ser&aacute; aleatoria, ya que estar&aacute; sujeta al Proceso de Selecci&oacute;n realizado por Mundo Total directamente y/o a trav&eacute;s de proveedores de servicios contratados por la Compa&ntilde;&iacute;a.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total establecer&aacute; diariamente el cupo m&aacute;ximo (limitado) de aprobaciones para el Plan, de manera que todos los Clientes que deseen participar podr&aacute;n presentar su solicitud, pero no todos resultar&aacute;n Clientes Seleccionados si para el momento de la aplicaci&oacute;n el cupo ya fue excedido.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">A los fines de participar en el Proceso de Selecci&oacute;n del Plan, Mundo Total podr&aacute; solicitar a los Clientes que suministren voluntariamente a Mundo Total la siguiente informaci&oacute;n personal y financiera:&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <ol>
                    <li>
                        <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">N&uacute;mero de c&eacute;dula de identidad.&nbsp;</span></span>
                            </span>
                        </p>
                    </li>
                </ol>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <ol start="2">
                    <li>
                        <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">N&uacute;mero del Registro de Informaci&oacute;n Fiscal (RIF).&nbsp;</span></span>
                            </span>
                        </p>
                    </li>
                </ol>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <ol start="3">
                    <li>
                        <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">N&uacute;mero de tel&eacute;fono m&oacute;vil.</span></span>
                            </span>
                        </p>
                    </li>
                </ol>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <ol start="4">
                    <li>
                        <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Condici&oacute;n laboral (p.ej., empleado, independiente, desempleado, etc.).</span></span>
                            </span>
                        </p>
                    </li>
                </ol>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <ol start="5">
                    <li>
                        <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Ingreso familiar mensual.</span></span>
                            </span>
                        </p>
                    </li>
                </ol>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total se reserva el derecho de solicitar nuevamente la informaci&oacute;n personal y financiera a los Clientes durante el Proceso de Selecci&oacute;n, cada vez que el Cliente aplique al Plan.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">El Promotor de Mundo Total rellenar&aacute; el formulario con la informaci&oacute;n personal y financiera del Cliente en un dispositivo electr&oacute;nico de la Compa&ntilde;&iacute;a (</span></span>
                    </span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>&ldquo;Formulario&rdquo;</strong></span></span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">), para su evaluaci&oacute;n por parte de Mundo Total. Adicionalmente, el Promotor podr&aacute; solicitar al Cliente que complete voluntariamente un cuestionario de predictibilidad y evaluaci&oacute;n crediticia en el dispositivo electr&oacute;nico suministrado por el Promotor.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Los Clientes Seleccionados podr&aacute;n participar nuevamente en el Plan tan pronto hayan pagado la totalidad de los Pagar&eacute;s. Por su parte, los Clientes que no hayan sido seleccionados podr&aacute;n aplicar nuevamente al Plan a los 90 d&iacute;as siguientes a su notificaci&oacute;n de rechazo.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total notificar&aacute; a los Clientes su aprobaci&oacute;n o rechazo al Plan mediante mensajes de texto (SMS) al n&uacute;mero de tel&eacute;fono m&oacute;vil suministrado al Promotor. Aquellos Clientes que hayan sido aprobados, conforme a la evaluaci&oacute;n realizada, se les indicar&aacute; el monto m&aacute;ximo del financiamiento que podr&aacute;n disfrutar con la suscripci&oacute;n de los pagar&eacute;s.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">[Los Clientes Seleccionados deber&aacute;n notificar a Mundo Total cualquier cambio en el n&uacute;mero de tel&eacute;fono m&oacute;vil y/o correo electr&oacute;nico suministrado al Promotor al momento de aplicar al Plan].&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>Otros T&eacute;rminos y Condiciones</strong></span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Al hacer uso del Plan, el Cliente da su consentimiento para recibir llamadas, mensajes de texto (SMS) y/o correos electr&oacute;nicos en el n&uacute;mero de tel&eacute;fono m&oacute;vil o correo electr&oacute;nico suministrado en la solicitud del Plan. Asimismo, el Cliente autoriza que (i) le sean enviados avisos de cobro y publicidad, (ii) su informaci&oacute;n sea incorporada y almacenada en la base de datos administrada por Acredita, C.A. (&ldquo;</span></span>
                    </span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>Base de Datos</strong></span></span><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">&rdquo;), y (iii) Acredita intercambie su informaci&oacute;n en la Base de Datos &nbsp;con terceros especializados, tales como instituciones del sector bancario, servicios de verificaci&oacute;n de identidad, bur&oacute;s de cr&eacute;dito, y empresas de outsourcing que nos presten servicios, a los fines de comprobar la identidad y veracidad de la informaci&oacute;n personal y bancaria suministrada. [En caso que el Cliente desee dejar de recibir publicidad a trav&eacute;s de mensajes de texto (SMS) y/o correo electr&oacute;nico deber&aacute; comunicarlo por escrito a Mundo Total.]</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total se reserva el derecho de cambiar, modificar, a&ntilde;adir o eliminar partes de estos T&eacute;rminos y Condiciones en cualquier momento, sin previo aviso a usted. Todos los cambios ser&aacute;n publicados en la p&aacute;gina web de Mundo Total y entrar&aacute;n en vigor de inmediato. Le recomendamos que consulte estos T&eacute;rminos y Condiciones en forma peri&oacute;dica para ver si se han registrado cambios. El uso del Plan por parte del Cliente despu&eacute;s de la publicaci&oacute;n de dichos cambios implica la aceptaci&oacute;n de los mismos.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total podr&aacute;, discrecional y unilateralmente, suspender de manera temporal o definitiva la implementaci&oacute;n del Plan, sin derecho a indemnizaci&oacute;n ni reparaci&oacute;n alguna a favor de los Clientes. As&iacute; mismo, la Compa&ntilde;&iacute;a fijar&aacute; discrecional y unilateralmente el monto m&aacute;ximo a ser financiado a cada Cliente a trav&eacute;s del Plan, as&iacute; como los l&iacute;mites m&aacute;ximos por d&iacute;a de operaciones disponibles bajo el Plan. En una primera etapa, el monto m&aacute;ximo del Plan a ser aprobado por Cliente Seleccionado y documentado a trav&eacute;s de los Pagar&eacute;s ser&aacute; de hasta [Cincuenta D&oacute;lares de los Estados Unidos de Am&eacute;rica (50,00 USD)]. Este monto podr&aacute; ser modificado por Mundo Total en cualquier momento y sin previo aviso.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Estos T&eacute;rminos y Condiciones estar&aacute;n vigentes y regular&aacute;n el uso del Plan desde el momento de la presentaci&oacute;n de la solicitud.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total no es responsable del contenido no oficial ni de aquel publicado en redes sociales o p&aacute;ginas web no oficiales de Mundo Total, relacionado con el Plan. En este sentido, Mundo Total renuncia a cualquier garant&iacute;a, ya sea expresa o impl&iacute;cita, en cuanto a la exactitud, calidad o cualquier otro aspecto de la informaci&oacute;n que contengan publicaciones ajenas a Mundo Total.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">El Cliente reconoce y acepta que Mundo Total no se hace responsable por las demoras en la funcionalidad del Plan ocasionadas por circunstancias o causas que no sean directamente imputables a Mundo Total o que est&eacute;n fuera de su control &nbsp;(causa de fuerza mayor), incluyendo, cortes en el suministro de energ&iacute;a el&eacute;ctrica, interrupciones del servicio de internet, dificultad en las comunicaciones telef&oacute;nicas, intervenci&oacute;n, disturbios, incumplimiento de los proveedores de Mundo Total o de cualquier tercero, u otras circunstancias similares o de cualquier otra &iacute;ndole que afecten o puedan afectar adversamente la funcionalidad del Plan. En este sentido, los Clientes no podr&aacute;n imputar responsabilidad alguna a Mundo Total, ni exigir resarcimiento alguno, en virtud de perjuicios resultantes de los casos antes mencionados, as&iacute; como por cualquier otra clase de da&ntilde;os, incluyendo da&ntilde;os indirectos, especiales o consecuentes que surjan o experimenten los Clientes.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Se reputar&aacute;n como v&aacute;lidas todas las notificaciones realizadas a los Clientes en la direcci&oacute;n de correo electr&oacute;nico principal o mensajes de texto (SMS) al n&uacute;mero de tel&eacute;fono suministrado a los fines del Plan. Ser&aacute; responsabilidad de los Clientes mantener actualizado y funcional su correo electr&oacute;nico y l&iacute;nea telef&oacute;nica, as&iacute; como de aplicar las medidas que sean necesarias para que las notificaciones no sean recibidas como correo no deseado.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Los Clientes Seleccionados no podr&aacute;n ceder sus derechos u obligaciones relacionados su selecci&oacute;n y/o con la adquisici&oacute;n de los Productos bajo el Plan, sin el consentimiento previo y por escrito de Mundo Total.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Mundo Total se reserva el derecho a delegar, ceder o novar de parcial o totalmente, sus derechos y obligaciones (incluyendo la cesi&oacute;n de los derechos de cr&eacute;dito o de cobro de sus facturas y/o pagar&eacute;s).</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><strong>Ley y Jurisdicci&oacute;n</strong></span></span>
                </p>
                <p style="margin-bottom: 0cm;line-height: 100%;background: transparent;font-style: normal;font-weight: normal;"><br></p>
                <p style="margin-bottom: 0.38cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Estos T&eacute;rminos y Condiciones incluyendo sin limitaci&oacute;n, su validez, interpretaci&oacute;n, exigibilidad, efectos y consecuencias, se regir&aacute;n por las leyes de la Rep&uacute;blica Bolivariana de Venezuela. Ambas partes escogen expresamente a la ciudad de Caracas como domicilio especial, &uacute;nico, exclusivo y excluyente, a la jurisdicci&oacute;n de cuyos tribunales acuerdan expresamente someterse.</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0.38cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">Una vez le&iacute;dos y entendidos los T&eacute;rminos y Condiciones, usted los acepta expresamente quedando comprometido a cumplirlos a cabalidad.&nbsp;</span></span>
                    </span>
                </p>
                <p style="margin-bottom: 0.38cm;line-height: 100%;background: transparent;"><span style="color: rgb(0, 0, 0);"><span style="font-style: normal;"><span style="font-weight: normal;">He le&iacute;do, revisado, analizado y entendido el contenido y las implicaciones de estos T&eacute;rminos y Condiciones. En este sentido, acepto expresamente dichos T&eacute;rminos y Condiciones quedando obligado a cumplirlo cabal y completamente.</span></span>
                    </span>
                </p>


            </div>

        </div>
    </ng-container>



</div>