<div class="mt-md-3 container-fluid">

    <div *ngIf="view != 1" class="mb-3 text-end ">
        <button (click)="closeInstallment()" type="button" class="btn-close " aria-label="Close"></button>
    </div>

    <div class="row mt-2">
        <div class="col-12  ">

            <h5 class="mb-1 text-secondary text-center ">

                Compras financiadas
            </h5>
        </div>


        <ng-container *ngIf="loading_person || loading">
            <div class="w-100 text-center">
                <img src="./assets/public/images/loading.gif  " alt=" ">
            </div>
        </ng-container>

        <ng-container *ngIf="!loading && person?.person">
            <div *ngIf="view == 1">
                <div class="mt-3 mb-4 my-3 p-3 card" *ngFor="let item of resp_invoiceCredits">
                    <div class="row row-k">
                        <div class="col-6">
                            <div class="small text-secondary">Total</div>
                            <h5 class="fw-bolder text-dark"> {{item?.totalAmount | currency:'USD':'': '1.0-2'}} <span class="small">USD</span></h5>
                        </div>

                        <div class="col-6 text-end">
                            <div class="small text-secondary">Fecha</div>
                            <div class=" text-dark small"> {{item?.dueDate | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                        </div>


                        <div class="col-6">
                            <div class="small text-secondary">ID Transaccion</div>
                            <div class=" text-dark"> {{item?.identifier }}</div>
                        </div>
                        <div class="col-6  text-end ">
                            <div class="small text-secondary">Estatus</div>
                            <div [ngClass]="{'text-success': item?.payment_status?.code == 'PaymentComplete','text-dark': item?.payment_status?.code != 'PaymentComplete'}" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.payment_status?.name}} </div>
                        </div>

                        <div class="col-6">
                            <div class="small text-secondary">Plan</div>
                            <div class="text-dark">{{item?.plan?.name}} </div>
                        </div>


                        <div class="col-6 text-end">
                            <div class="small text-secondary">Cuotas </div>
                            <div>{{item?.plan?.number_installment}}

                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="small text-secondary">Monto inicial</div>
                            <div class=" text-dark">{{item?.minimumPaymentDue | currency:'USD':'': '1.0-2'}} <span class="small">USD</span></div>
                        </div>

                        <div class="col-6 text-end">
                            <div class="small text-secondary">Monto financiado</div>
                            <div class=" text-dark">{{item?.amount | currency:'USD':'': '1.0-2'}} <span class="small">USD</span></div>

                        </div>


                        <div class="col-6 ">
                            <div class="small text-secondary">Monto pendiente</div>
                            <div class=" text-dark">{{item?.dueBalance | currency:'USD':'': '1.0-2'}} <span class="small">USD</span></div>
                        </div>

                        <!-- <div class="col-12 " (click)="getPersonInstallmentsInvoiceCredits(item)"> -->
                        <div class="col-12" [routerLink]="['/invoice-detail/',item?.code]">

                            <a class="text-primary cursor-pounter float-end"></a>
                            <div class="d-grid gap-2">
                                <button class="btn btn-primary" type="button">Ver cuotas</button>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

            <div class="" *ngIf="view == 2">
                <div class="col-12  ">
                    <div *ngIf="installments && view == 2" class="text-center">
                        <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>

                    </div>

                    <div *ngFor=" let item of installments;let i = index" class="bg-white mt-4 my-3 p-3 card">

                        <div class="row mt-2 row-k">
                            <div class="col-6 ">

                                <h5 class="fw-bolder text-dark"> {{ item?.installMent ?.order
                                    < 1 ? 'Inicial': 'cuota '+item?.installMent ?.order}}</h5>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Fecha</div>
                                <div class=" text-dark small"> {{item?.installMent ?.date | date: 'dd/MM/yyyy'}}</div>

                            </div>
                            <div class="col-6">
                                <div class="small text-secondary">Total</div>
                                <div class=" text-dark"> {{item?.installMent ?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Estatus</div>
                                <div [ngClass]="{'text-success': item?.installMent ?.payment_status?.code == 'PaymentComplete','text-dark': item?.installMent ?.payment_status?.code != 'PaymentComplete'}" class="text-dark" [title]="item?.installMent ?.payment_status?.description">{{item?.installMent ?.payment_status?.name}} </div>
                            </div>


                            <div class="col-12 ">
                                <a *ngIf="item?.installMent ?.payment_status?.code == 'PaymentComplete'" (click)="getPersonInstallmentpayment(item?.installMent )" class="text-primary cursor-pounter float-end">Ver pagos</a>

                                <div class="small text-secondary">Fecha de Pago</div>
                                <div class=" text-dark"> {{item?.installMent ?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>


                            </div>
                            <div class="col-6 ">
                                <div class="small text-secondary">Pagada</div>
                                <div class=" text-dark"> {{ (item?.installMent ?.amount - item?.installMent ?.dueBalance ) | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Restante</div>
                                <div class=" text-dark"> {{item?.installMent ?.dueBalance | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <div class="col-6 text-end" *ngIf="item?.totalpaymentReference  > 0">
                                <div class="small text-secondary">Total (Por conciliar)
                                </div>
                                <div class=" text-dark"> {{item?.totalpaymentReference | currency:'USD':'': '1.0-2'}} USD</div>
                            </div>

                            <!-- <div class="col-6  text-end" *ngIf="item?.totalpaymentReference > item?.installMent ?.amount">
                                <div class="small text-secondary"> Total exedente (Por conciliar ) </div>
                                <div class=" text-dark"> {{ (item?.totalpaymentReference - item?.installMent ?.dueBalance) | currency:'USD':'': '1.0-2'}} USD</div>
                            </div> -->

                        </div>
                        <div class="row mt-2 row-k" *ngIf="item?.payments?.length > 0 || item?.paymentReference?.length > 0">

                            <div class="fw-bold text-secondary">Pagos</div>

                            <ng-container class="mt-3" *ngFor=" let item2 of item?.payments ;let i = index">

                                <div class="col-6 ">
                                    <div class="small ">

                                        <span>
                                               {{item2?.payment?.paymentMethodId}}
                                            <div class=" text-secondary small">  {{item2?.payment?.code}}</div>
                                          

                                            </span>

                                    </div>

                                </div>
                                <div class="col-6  text-end small">
                                    <div class=" text-dark "> {{item2?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                                    <div class="text-success" *ngIf="item2?.payment?.reconciled_payment">Conciliado </div>
                                    <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo()" class="badge text-bg-warning">Por conciliar</span>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container class="mt-3" *ngFor=" let item2 of item?.paymentReference ;let i = index">

                                <div class="col-6 ">
                                    <div class="small ">

                                        <span>
                                               {{item2?.payment?.paymentMethodId}}
                                            <div class=" text-secondary small">  {{item2?.payment?.code}}</div>
                                          

                                            </span>

                                    </div>

                                </div>
                                <div class="col-6  text-end small">
                                    <div class=" text-dark "> {{item2?.payment?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                                    <div class="text-success" *ngIf="item2?.payment?.reconciled_payment">Conciliado </div>
                                    <div *ngIf="!item2?.payment?.reconciled_payment"><span (click)="redirectTo()" class="badge text-bg-warning">Por conciliar</span>
                                    </div>
                                </div>

                            </ng-container>


                        </div>

                        <!-- installmentNexPayment == item?.installMent?.code && -->
                        <div *ngIf=" (( item?.installMent ?.dueBalance - item?.totalpaymentReference ) > 0) &&  invoiceSelected?.payment_status?.code != 'PaymentTrash'" class="col-12 mt-3">

                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-primary mt-2 btn-sm" (click)="pagar(invoiceSelected?.code,item?.installMent?.code)"> Pagar {{ (item?.installMent ?.dueBalance - item?.totalpaymentReference) | currency:'USD':'': '1.0-2'}} USD faltantes </button>

                            </div>
                        </div>

                    </div>


                </div>

            </div>

            <div class=" mt-2" *ngIf="view == 3">
                <div class="col-12  ">
                    <div *ngIf="installments" class="text-center">
                        <div class=" text-primary"> {{invoiceSelected?.identifier }}</div>

                        <div class="fw-bold"> Pago {{ installMentSelected?.order
                            < 1 ? 'Inicial': 'Cuota '+ installMentSelected?.order }} </div>

                        </div>


                        <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm">

                            <div class="row mt-2 row-k">
                                <div class="col-6 mt-2">
                                    <h5 class="fw-bolder text-dark"> Pago</h5>
                                </div>
                                <div class="col-6 mt-2  text-end">
                                    <div class="small text-secondary">Código</div>
                                    <div class=" text-dark small"> {{payment?.payment?.code}}</div>
                                </div>
                                <div class="col-6">
                                    <div class="small text-secondary">Monto total</div>
                                    <div class=" text-dark"> {{payment?.payment?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                                </div>
                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>
                                    <div class="text-success">Pago conciliado </div>
                                </div>
                                <div class="col-12 ">
                                    <div class="small text-secondary">Fecha</div>
                                    <div class=" text-dark"> {{payment?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </div>

                                <div class="col-6 ">
                                    <div class="small text-secondary">Forma de Pago</div>
                                    <div class=" text-dark" [title]="payment?.payment?.payment_method?.description"> {{payment?.payment?.payment_method?.name}}</div>

                                </div>
                                <div class="col-6  text-end">
                                    <div class="small text-secondary">Número de confirmación</div>
                                    <div class=" text-dark"> {{payment?.payment?.paymentMethodId}}</div>
                                </div>

                            </div>

                            <div class="row mt-3 row-k" *ngIf="payment?.installMentsPayment?.length > 0">


                                <div class="fw-bold text-secondary col-12 mt-2">Distribución</div>
                                <ng-container *ngFor=" let item of payment?.installMentsPayment ;let i = index">
                                    <div class="col-8">
                                        <div class="small text-primary">{{ item?.installMent?.order
                                            < 1 ? 'Inicial': 'Cuota '+ item?.installMent?.order }} </div>
                                                <div class="small text-secondary small">{{item?.installMent?.code }}</div>

                                        </div>
                                        <div class="col-4 text-end ">
                                            <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.0-2'}} USD</div>
                                        </div>

                                </ng-container>

                                </div>
                                <div class="row mt-2" *ngIf="payment?.paymen_categ_concept_deduc?.length > 0">

                                    <ng-container *ngFor=" let item of payment?.paymen_categ_concept_deduc ;let i = index">
                                        <div class="col-8" *ngIf="item?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                            <div class="small text-primary">{{item?.category_concept_deduction?.concept_deduction?.name}}
                                                <span class="text-secondary" *ngIf="item?.category_concept_deduction.isPorcent">{{item?.category_concept_deduction?.amount}}%</span> </div>
                                        </div>
                                        <div class="col-4 text-end " *ngIf="item?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                            <div class=" text-dark"> {{item?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        </div>

                                    </ng-container>

                                </div>


                            </div>


                        </div>

                    </div>


        </ng-container>


        <div *ngIf="!loading && !loading_person &&  person?.person && resp_invoiceCredits?.length < 1" class="col-12  ">
            <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                <div>No se encontraron Compras financiadas</div>
            </div>
        </div>

        <div *ngIf="!loading_person && !person?.person">
            <div>No se encontro person con este id: {{queryParams?.code}}</div>
        </div>




        </div>