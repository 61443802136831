import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-biometria',
  templateUrl: './biometria.component.html',
  styleUrls: ['./biometria.component.css']
})
export class BiometriaComponent implements OnInit {
  timeLeft: number = 10;  // Tiempo en segundos
  interval: any;
  percentLeft: number = 100;  // Porcentaje de tiempo restante

  constructor(private router: Router) {}


  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    const totalTime = this.timeLeft;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.percentLeft = (this.timeLeft / totalTime) * 100;
      } else {
        this.completeTimer();
      }
    }, 1000);
  }

  completeTimer(): void {
    clearInterval(this.interval);
    this.onTimerComplete();  // Llama a la función cuando el temporizador termine
  }

  onTimerComplete(): void {
    console.log('¡El temporizador ha terminado!');
    
    this.router.navigate(['/identity_verif']); // Reemplaza 'otra-pagina' con la ruta de la página a la que deseas redirigir

    // Aquí puedes llamar a otra función o agregar la funcionalidad que desees.
  }

  // Función para calcular el estilo de rotación

  getRotationStyle(): string {

    const progressPercent = 100 - (this.timeLeft / 10) * 100; // Calcular el porcentaje de progreso restante
    const rotationDegrees = progressPercent * 1.8; // Convertir el porcentaje a grados de rotación (180 grados para 100%)
    
    return `rotate(${rotationDegrees}deg)`;
  }
  
}
