
<div class="text-center" *ngIf="timeStatus">

<div class="col-12 col-md-4 offset-md-4 pb-4">
    <img width="98%" id="appear" class="text-center" [src]="sanitize('../assets/public/images/cerrared.png')" alt="IconoWhatsApp" />
    </div>

<h2 class="mt-3 text-secondary text-center">Por favor cierre esta pestaña y continúe el proceso en la aplicación. </h2>

<!-- <button (click)="cerrarPestana()" class="btn btn-primary text-center mt-4" >Continuar</button>--> 
</div>



<div class="text-center" *ngIf="!timeStatus">

    <div class="col-12 col-md-4 offset-md-4 pb-4">
    
        <ng-container class="mt-3">
            <div class="w-100 text-center mt-2">
                <img src="./assets/public/images/loading.gif  " alt=" ">
            </div>
        </ng-container>
    
    
    </div>
    
    <h3 class="mt-3 text-secondary text-center">Por favor espere. </h3>
    
    <!-- <button (click)="cerrarPestana()" class="btn btn-primary text-center mt-4" >Continuar</button>--> 
    </div>
    
