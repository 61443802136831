
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class RedirecFormService {
  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  public postRedirect(url="https://autodev-join.slatesuper.com.au/confirm",params: any) {
    const form = this.document.createElement("form");
    form.method = "POST";
    form.target = "_blank";
    //form.target = "myIframe";
    form.action = url;
    // form.setAttribute("method", "POST");
    // form.setAttribute("target", "_top");
    // form.setAttribute(
    //   "action",
    //   "https://autodev-join.slatesuper.com.au/confirm"
    // );
   // console.log(params);
    for (let prop in params) {
      const input = this.document.createElement("input");
      input.type = "hidden";
      input.name = prop;
      input.value = params[prop];
      input.setAttribute("type", "hidden");
      // input.setAttribute("name", prop);
      // input.setAttribute("value", params[prop]);
      form.append(input);
    }
    this.document.body.appendChild(form);
    
    form.submit();
  }
}
