import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';

@Component({
    selector: 'akeela-registro',
    templateUrl: './registro.component.html',
    styleUrls: ['./registro.component.css'],
})
export class RegistroComponent implements OnInit {

    templates: any = {};
    object: any = {};
    form:any;
    submit_disabled = false;
    lang:any;
    errors:any = [];

    correoMinLength:any;
    correoMaxLength:any;
    passwordMinLength:any;
    passwordMaxLength:any;

    typeInputPass = false;

    correo:any;
    ip:any;
    password:any;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public _auth : AuthService,
        private _user:UserService
    ) {

        if(this._auth.isAuth){
            this.router.navigate([environment.initRoute], {});
      
        }
      
     }

    ngOnInit() {
        
        this.correoMinLength = 6;
        this.correoMaxLength = 30;
        this.passwordMinLength = 6;
        this.passwordMaxLength = 12;

        this.form = this.formBuilder.group({

            nombre: ['',],
            apellido: [''],
            telefono: [''],
            type: ['PERSON',[Validators.required]],
            correo: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]]
       
        });
    }


    submit = async () => {

      console.clear();
        this.errors = [];
        if ( this.form.valid) {
            this.submit_disabled = true;
            const form = this.form.getRawValue();

             const form_resp = {

                   name:form.nombre,
                   lastName:form.apellido,
                   type:form.type,
                   username:form.correo,
                   password:form.password,
                   audience:"NATURAL",
                  // type_contact:"PHONE_CELL",
                  // contact:form.telefono,
                   verificated_contact:false,
                   role: form.type == "COMPUTER"?"COMPUTER_ROLE":"USER_ROLE"
             } 

            try {
         
              let resp = await this._user.createUser(form_resp);

              alertifyjs.success('Creacion exitosa');

               this.router.navigate([environment.loginRoute], {});
               
             } catch (error:any) {
                alertifyjs.error('Error al crear el usuario');

                if(error?.error?.errors){
                    this.errors = error?.error?.errors;
                }

                if(error?.error?.msg){
                    this.errors =   [...this.errors,{msg:error?.error?.msg} ];
                }

                console.error(error);
                this.submit_disabled = false;

         
             }

        }
    }
  

}
